
<template>
  <div>
    <page-header title="Inscrições"></page-header>
    <v-card style="border-radius:12px" flat outlined>
      <v-card-title primary-title class='primary white--text py-1'>
        <v-row class="px-3 py-4 pb-5" justify="space-between">
          <v-col cols='12' md='9' class="pa-0">
            <div style="word-break: break-word">Inscrições por curso</div>
          </v-col>
        </v-row>
      </v-card-title>
      <card-loading-transparent v-if="loading"></card-loading-transparent>
      <v-card-text class="px-0 pb-3" v-show="!loading">
        <div class="d-flex justify-center mt-2 mb-1 pb-2">
          <year-picker @selectedYear="handleSelectedYear" :year="current_year" :disabledPrevious="previousYear" :disabledNext="nextYear"/>
        </div>
        <counter-by-country :current_year="current_year" class="px-5"/>
        <done-forms-enrollment-counter-table :current_year="current_year" @empityData="handleEmpityData"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'DoneFormsEnrollmentCountersIndex',
  data() {
    return {
      current_year: this.$dayjs().year(),
      loading: false,
      previousYear: false,
      nextYear: false,
      year_aux: this.$dayjs().year(),
    }
  },
  components: {
    CounterByCountry: () => import('../counters/CounterByCountry.vue'),
    DoneFormsEnrollmentCounterTable: () => import('./counter_tabble/DoneFormsEnrollmentCounterTable.vue')
  },
  methods: {
    handleEmpityData(data){
      if(data) {
        if(this.current_year >= this.year_aux) {
          this.nextYear = true
        }else{
          this.previousYear = true
        }
      }else{
        this.previousYear = false
        this.nextYear = false
      }
    },
    handleSelectedYear(e) {
      this.getData()
      this.year_aux = this.current_year
      this.current_year = e
    },
    getData(){
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500);
    }
  },
  mounted () {
    this.getData();
  },
}
</script>

<style>
  
</style>